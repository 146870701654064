$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; details {
  position: relative;

  .topic-body .cooked &,
  .d-editor-preview,
  &.details__boxed {
    background-color: var(--primary-very-low);
    padding: 0.25rem 0.75rem;
    margin-bottom: 0.5rem;

    &:not([open]) {
      &:hover,
      &:focus,
      &:focus-within {
        background-color: var(--d-hover);
      }
    }

    aside.quote .title,
    blockquote,
    code {
      background-color: rgba(var(--primary-rgb), 0.05);
    }
  }
}

details.details__boxed {
  summary {
    font-weight: bold;
  }
}

details > *,
details .lightbox-wrapper {
  display: none;
}

details,
summary {
  outline: none;
}

summary {
  > p {
    margin-block: 0;
    padding-block: 1rem;
  }
}

summary:first-of-type {
  cursor: pointer;
  display: block;
}

summary::before {
  content: "\25BA";
  margin-right: 0.25em;
}

details[open] > *,
details[open] .lightbox-wrapper {
  display: block;
}

details[open] > summary::before,
details.open > summary::before {
  content: "\25BC";
}

details[open] > summary:first-of-type ~ *,
details.open > summary:first-of-type ~ * {
  display: block;
}

/* hide native indicator */
summary::-webkit-details-marker {
  display: none;
}

.topic-body .cooked,
.d-editor-preview {
  .elided {
    background-color: unset;
    padding: 0;

    &:not([open]) {
      //specificity needed to overrule non-elided
      &:hover,
      &:focus,
      &:focus-within {
        background-color: unset;
      }

      summary:hover {
        background: var(--d-hover);
      }
    }

    &[open] {
      background: var(--primary-very-low);
      padding: 0.5rem 0.75rem;

      summary {
        padding: 0;
      }
    }

    summary::before {
      content: "" !important;
      display: none;
    }

    summary {
      @include unselectable;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-sizing: border-box;
      margin: 0;
      padding: 0.5rem 0.75rem;
      color: var(--primary-medium);
      background: var(--primary-very-low);
      width: min-content;
      line-height: 1;

      &:hover,
      &:focus,
      &:focus-within {
        color: var(--primary);
        cursor: pointer;
      }
    }
  }
}
